<template>
  <div class="home" style="overflow: auto; height: inherit">
    <v-container fluid style="height:100%;">
      <v-row class="mb-4">
        <v-col cols="6">
            <v-text-field
              v-model="url"
              label="Main input"
              class="mb-8"
              hide-details="auto"
            ></v-text-field>

            <v-textarea
              clearable
              v-model="jsonToSend"
              class="mt-4"
              clear-icon="mdi-close-circle"
              label="Text"
              height="100px"
              value="This is clearable text."
            ></v-textarea>
            <v-btn color="primary" @click="sendToApi">Send</v-btn>
            <v-jsoneditor v-model="jsonFromAPI" :options="options" :plus="false" height="700px" />
        </v-col>
      </v-row>
      
    </v-container>
  </div>
</template>

<script>
import { i18n, loadLanguageAsync } from "@/i18n";
import VJsoneditor from 'v-jsoneditor/src/index'
// @ is an alias to /src
import GenericDataService from "@/services/GenericDataService";
export default {
  name: "NicoTest",
  components: {
      VJsoneditor
  },
  data() {
    return {
      url:"",
      jsonToSend:"",
      options:{
        modes: ['code', 'tree', 'text', 'form']
      },
      jsonFromAPI: {},
      json:{},
    }
  },
  computed: {},
  methods: {
    sendToApi: function () {
      if (this.jsonToSend != "") {
        let payload = JSON.parse(this.jsonToSend);
        GenericDataService.postData(this.url, payload)
          .then((response) => {
            this.jsonFromAPI = response;
            this.error = false;
          })
          .catch((error) => {
            this.jsonFromAPI = error.response;
            this.error = true;
          });
      } else {
        GenericDataService.getData(this.url)
          .then((response) => {
            this.jsonFromAPI = response;
            this.error = false;
          })
          .catch((error) => {
            this.jsonFromAPI = error.response;
            this.error = true;
          });
      }
    }
  },
  created() {},
  watch: {},
};
</script>
<style lang="scss">
.organizations {
  max-width: 250px;
}

.container {
  height: auto;
  overflow: auto;
}
</style>
